import * as React from 'react';
import { Helmet } from 'react-helmet';
import { StaticImage } from 'gatsby-plugin-image';

import Layout from '../components/layouts/core';

import colors from '../colors/colors';
import FlexWrapper from '../components/display/FlexWrapper';

const ogDescription = `Boogie-Woogie is an NFT collection from Dandy Lines.
We select works of art as inspiration to produce unique abstract representations 
using generative code. Controlling color selection and scale ensures the 
random output of the Boogie-Woogie NFT is unique and captures the essence of the original.`;

const PartnersPage = () => (
  <>
    <Helmet>
      <title>Boogie-Woogie Partners</title>
      <meta name='twitter:card' content='summary' />
      <meta name='twitter:site' content='@BoogieWoogieNFT' />
      <meta name='twitter:creator' content='@ics_jim' />
      <meta property='og:url' content='https://boogie-woogie.io/partners' />
      <meta property='og:title' content='Boogie-Woogie Partners' />
      <meta property='og:type' content='website' />
      <meta property='og:description' content={ogDescription} />
      <meta property='og:image' content='https://sale.boogie-woogie.io/boogie-woogie-auction-site-run-one.png' />
      <meta property='og:image:width' content='1200' />
      <meta property='og:image:height' content='630' />
    </Helmet>
    <Layout>
      <div
        style={{
          display: 'block',
          width: '100%',
          padding: 'calc(2.5rem + 2.5vw) 0',
          textAlign: 'center',
          backgroundColor: colors.red,
        }}
      >
        <h1
          style={{
            display: 'block',
            width: '100%',
            margin: 0,
            color: colors.white,
            fontWeight: 300,
          }}
        >
          Partners
        </h1>
      </div>
      <div
        style={{
          minHeight: 'calc(100vh - 20rem',
          margin: '2rem auto',
          padding: '0 2rem',
          maxWidth: '30rem',
        }}
      >
        <h2
          style={{
            margin: '0 0 1rem',
            fontFamily: `'Staatliches', 'Roboto', sans-serif`,
            fontSize: '2rem',
            letterSpacing: '0.075rem',
            textAlign: 'center',
            color: 'var(--brand-color-blue)',
          }}
        >
          As Seen On:
        </h2>
        <FlexWrapper
          justify='center'
          align='center'
          style={{
            maxWidth: '400px',
            margin: '0 auto',
          }}
        >
          <a href='https://nftcalendar.io/event/boogie-woogie-run-1/'>
            <StaticImage src='../images/nftcalendar-500x500.png' alt='NFT Calendar' />
          </a>
        </FlexWrapper>
      </div>
    </Layout>
  </>
);

export default PartnersPage;
